import { inject } from "mobx-react";
import { Fragment, useMemo } from "react";
import { isPublishRecord } from "@inferno/renderer-shared-core";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { DATETIME_DISPLAY_FORMATS, zonedTimeFormat } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../../../core/ui";
import { getCoastLinks } from "../../lib";
import { CoastLinkedGuestItem } from "../CoastLinkedGuests.component";
import { CoastFeedShowItemProps } from "./CoastFeedShowItem.component";
import { CoastShowDate } from "./CoastShowDate.component";

export const CoastFeedUpcomingShowsPreviewItem = inject("store")(({ item, store }: CoastFeedShowItemProps) => {
  const { record } = item;

  if (!store || !isPublishRecord(record)) {
    return null;
  }

  const { slug, payload } = record;
  const { summary } = payload;
  const timezone = store.site.index?.timeZone || "UTC";
  const showUrl = reverseRoute(store.site, "shows_detail", { slug }) || "#";

  const guests = useMemo(() => (isPublishRecord(record) ? getCoastLinks(record, "guests") : null), [record]);
  const showDayOfWeek = useMemo(
    () =>
      zonedTimeFormat({
        date: record.payload.fields.show_date.value || record.pub_start,
        outputFormat: DATETIME_DISPLAY_FORMATS.DAY_OF_WEEK,
        timezone,
      }),
    [record.payload.fields.show_date.value, record.pub_start, timezone],
  );

  return (
    <div className="coast-feed-item">
      <div className="short-date">
        <CoastShowDate item={record} isShortDate={true} store={store} />
      </div>
      <div>
        <MagicLink to={showUrl} context={slug} className="item-title">
          {showDayOfWeek} - {summary.title}
        </MagicLink>
        {guests?.map((guest, idx) => (
          <Fragment key={idx}>
            <CoastLinkedGuestItem guest={guest} />
            {idx < guests.length - 1 ? <span> | </span> : null}
          </Fragment>
        ))}
      </div>
    </div>
  );
});
