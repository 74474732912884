import { PublishRecordFragment } from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";
import { DATETIME_DISPLAY_FORMATS, zonedTimeFormat } from "@inferno/renderer-shared-core";

export interface CoastShowDateProps {
  item: PublishRecordFragment;
  isShortDate: boolean;
  store: Store;
}

export const CoastShowDate = ({ item, store, isShortDate }: CoastShowDateProps) => {
  const timezone = store.site.index.timeZone || store.env.DEFAULT_TIMEZONE;
  const itemDate = item.payload.fields.show_date.value || item.pub_start;

  return (
    <time className="item-published">
      {isShortDate ? (
        <>
          <span>
            {zonedTimeFormat({
              date: itemDate,
              timezone,
              outputFormat: DATETIME_DISPLAY_FORMATS.SHORT_MONTH,
            })}
          </span>
          <span>
            {zonedTimeFormat({
              date: itemDate,
              timezone,
              outputFormat: DATETIME_DISPLAY_FORMATS.SHORT_DAY_UNPADDED,
            })}
          </span>
        </>
      ) : (
        zonedTimeFormat({
          date: itemDate,
          timezone,
          outputFormat: DATETIME_DISPLAY_FORMATS.FULL_MONTH_SHORT_DAY_FULL_YEAR,
        })
      )}
    </time>
  );
};
