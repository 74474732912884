import loadable from "@loadable/component";
import { inject } from "mobx-react";
import { Fragment, useMemo } from "react";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { Heading, MagicLink } from "../../../../core/ui";
import { CoastFeedShowsProps } from "../../lib/coastFeed";
import { CoastFeedUpcomingShowsPreviewItem } from "./CoastFeedUpcomingShowsPreviewItem.component";

const CoastShowPlaceholder = loadable(() => import("../CoastShowPlaceholder.component"));

export const CoastFeedUpcomingShowsPreview = inject("store")(({ store, content, type, title }: CoastFeedShowsProps) => {
  if (!store || !content?.length) {
    return <CoastShowPlaceholder />;
  }

  const { site, page } = store;

  // we want these sorted earliest to latest. Pubsub doesn't allow that.
  // so we get all (usually up to 14) and reverse it
  const sortedCards = useMemo(
    () =>
      content
        .slice(
          // homepage also has tonight show block - skip that show from the upcoming feed
          page.currentPage?.name === "home" ? -5 : -4,
          page.currentPage?.name === "home" ? -1 : undefined,
        )
        .reverse(),
    [content, page.currentPage?.name],
  );

  // might happen if we're on the home page and only the tonight's show is scheduled
  if (!sortedCards?.length) {
    return <CoastShowPlaceholder />;
  }

  const moreLink = reverseRoute(site, "upcoming_shows");

  return (
    <Fragment>
      <Heading level={3}>{title}</Heading>
      <div className="upcoming-shows-preview">
        <div className="feed-cards">
          {sortedCards.map(item => (
            <CoastFeedUpcomingShowsPreviewItem key={item.id} item={item} type={type} />
          ))}
        </div>

        {moreLink ? (
          <div className="more-container">
            <MagicLink className="more" to={moreLink}>
              Full Schedule &raquo;
            </MagicLink>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
});

export default CoastFeedUpcomingShowsPreview;
