import classNames from "classnames";
import { inject } from "mobx-react";
import { Fragment, useMemo } from "react";

import { LinkedItemRecordFragment, PublishRecordFragment } from "@ihr-radioedit/inferno-webapi";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../../core/ui";
import { getCoastLinks } from "../lib";
import "./CoastLinkedItems.style.scss";
import type { Store } from "@inferno/renderer-shared-core";
import type { SrcSet } from "@inferno/renderer-shared-core";
import { ResponsiveImage } from "../../../core/components/ResponsiveImage.component";
import { placeholderImage } from "@ihr-radioedit/inferno-core";

interface CoastLinkedGuestsProps {
  item: PublishRecordFragment;
  titleOnSameLine: boolean;
  separator?: string;
  store?: Store;
  guestImage?: boolean;
}

interface CoastLinkedGuestItemProps {
  guest: LinkedItemRecordFragment;
  store?: Store;
  guestImage?: boolean;
}

export const CoastLinkedGuestItem = inject("store")(({ store, guest, guestImage }: CoastLinkedGuestItemProps) => {
  if (!store) {
    return null;
  }

  const width = 134;
  const height = 134;
  const img = guest.summary?.image || "";
  const alt = guest.summary?.title;
  const placeholderSrc = `${placeholderImage}?ops=resize(${width},${height}),quality(10)`;
  const guestUrl = reverseRoute(store.site, "guests_detail", { slug: guest.slug }) || "#";
  const srcset: SrcSet[] = [
    {
      url: `${img}?ops=gravity(%22center%22),fit(${width},${height}),quality(80)`,
      descriptor: "330w",
    },
    {
      url: `${img}?ops=gravity(%22center%22),fit(${width},${height}),quality(80)`,
      descriptor: "1024w",
    },
  ];

  return (
    <>
      {guestImage ? (
        <div className="guest-details">
          <MagicLink to={guestUrl} context={guest.slug}>
            <ResponsiveImage
              alt={`profile image of ${alt}`}
              src={placeholderSrc}
              placeholder={placeholderSrc}
              srcset={srcset}
              initialWidth={134}
              initialHeight={134}
            />
          </MagicLink>
          <MagicLink to={guestUrl} context={guest.slug}>
            {guest.summary.title}
          </MagicLink>
        </div>
      ) : (
        <MagicLink to={guestUrl} context={guest.slug}>
          {guest.summary.title}
        </MagicLink>
      )}
    </>
  );
});

export const CoastLinkedGuests = inject("store")(
  ({ item, titleOnSameLine, separator = ", ", store, guestImage }: CoastLinkedGuestsProps) => {
    const guests = useMemo(() => getCoastLinks(item, "guests"), [item]);

    if (!guests?.length || !store) {
      return null;
    }

    const className = classNames("coast-linked-guests coast-linked-items", { "title-on-same-line": titleOnSameLine });

    return (
      <div className={className}>
        <span className="linked-guest-header">Guests{titleOnSameLine ? ":" : ""}</span>
        <div className="linked-guest-value">
          {guests.map((guest, idx) => (
            <Fragment key={idx}>
              <CoastLinkedGuestItem guest={guest} guestImage={guestImage} />
              {idx < guests.length - 1 && titleOnSameLine ? <span>{separator}</span> : null}
            </Fragment>
          ))}
        </div>
      </div>
    );
  },
);
